import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DateTime} from 'luxon';
import {Auth, Firestore} from 'firebaseConfig';
import {torchhoodActions} from 'state/airPollution/slices/torchhood';

const useAirPollTorchhood = () => {
  const torchhood = useSelector(state => state.airPollution.torchhood);
  const dispatch = useDispatch();
  const duplicateRender = useRef(false);

  useEffect(() => {
    // Prevent duplicate renders caused by React 18 strict mode
    if (duplicateRender.current) return;
    duplicateRender.current = true;

    // Only run remainder of code if hook untouched; Prevents duplicate invocations;
    if (torchhood.touched) return;
    dispatch(torchhoodActions.setTouched(true));

    let unsubscribe = null;
    // Create functions to get state or forget state depending on user's auth status
    const getState = () => {
      const minLogYear = DateTime.now().get('year') - 4;
      const query = Firestore.query(
        Firestore.collection('Air-Pollution'),
        Firestore.where('Date', '>=', new Date(`${minLogYear}-01-01`)),
        Firestore.where('Unit', '==', 'Torchhood'),
      );
      const snapData = snap => {
        const all = [];
        let lastLog = {};
        const yearlyMap = {};
        const monthlyMap = {};
        const dailyMap = {};

        snap.forEach(doc => {
          const docData = doc.data();
          const DateMillis = docData?.Date?.toMillis ? docData.Date.toMillis() : DateTime.now().toMillis();
          const DateYear = DateTime.fromMillis(DateMillis).toFormat('yyyy');
          const DateMonth = DateTime.fromMillis(DateMillis).toFormat('yyyy/LL');
          const DateDay = DateTime.fromMillis(DateMillis).toFormat('yyyy/LL/dd');
          const formattedDoc = {
            ...docData,
            Date: DateMillis,
            DateYear,
            DateMonth,
            DocId: doc.id,
            WeightTotal: !docData.Containers ? 0 : docData.Containers.reduce((acc, cV) => {
              return acc + cV.Weight;
            }, 0),
            System: {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : DateTime.now().toMillis(),
            },
          };

          // Add all Torchhood log documents to all array
          all.push(formattedDoc);
          // Ensure last Wasterwater log entered is used for lastLog value
          if (!lastLog?.Date || lastLog.Date < DateMillis) lastLog = formattedDoc;
          // Map Torchhood documents using DateYear as the key; Will be used to aggregate fields by year
          yearlyMap[DateYear] = yearlyMap?.[DateYear] ? [...yearlyMap[DateYear], formattedDoc] : [formattedDoc];
          // Map Torchhood documents using DateMonth as the key; Will be used to aggregate fields by year and month
          monthlyMap[DateMonth] = monthlyMap?.[DateMonth] ? [...monthlyMap[DateMonth], formattedDoc] : [formattedDoc];
          // Map Torchhood documents using DateDay as the key; Will be used to aggregate fields by year, month and day.
          dailyMap[DateDay] = dailyMap?.[DateDay] ? [...dailyMap[DateDay], formattedDoc] : [formattedDoc];
        });

        // Aggregate all values in yearlyMap & monthlyMap by their respective keys
        const yearlySummary = Object.keys(yearlyMap).map(Key => {
          return yearlyMap[Key].reduce((acc, cV) => {
            if (Object.keys(acc).length === 0) return {...cV, Key, LogCount: 1};
            else {
              return {
                Key: acc.Key,
                Hours: Math.round(acc.Hours + cV.Hours),
                MeterStart: acc?.MeterStart && acc.MeterStart < cV.MeterStart ? acc.MeterStart : cV.MeterStart,
                MeterEnd: acc?.MeterEnd && acc.MeterEnd > cV.MeterEnd ? acc.MeterEnd : cV.MeterEnd,
                WeightStart: acc?.WeightStart && acc.WeightStart < cV.WeightStart ? acc.WeightStart : cV.WeightStart,
                WeightEnd: acc?.WeightEnd && acc.WeightEnd > cV.WeightEnd ? acc.WeightEnd : cV.WeightEnd,
                WeightTotal: acc.WeightTotal + cV.WeightTotal,
                // Increment number of wastewater logs; May be used to find average of Hours and other fields
                LogCount: acc.LogCount + 1,
              };
            }
          }, {});
        });
        const monthlySummary = Object.keys(monthlyMap).map(Key => {
          return monthlyMap[Key].reduce((acc, cV) => {
            if (Object.keys(acc).length === 0) return {...cV, Key, LogCount: 1};
            else {
              return {
                Key: acc.Key,
                Hours: Math.round(acc.Hours + cV.Hours),
                MeterStart: acc?.MeterStart && acc.MeterStart < cV.MeterStart ? acc.MeterStart : cV.MeterStart,
                MeterEnd: acc?.MeterEnd && acc.MeterEnd > cV.MeterEnd ? acc.MeterEnd : cV.MeterEnd,
                WeightStart: acc?.WeightStart && acc.WeightStart < cV.WeightStart ? acc.WeightStart : cV.WeightStart,
                WeightEnd: acc?.WeightEnd && acc.WeightEnd > cV.WeightEnd ? acc.WeightEnd : cV.WeightEnd,
                WeightTotal: acc.WeightTotal + cV.WeightTotal,
                // Increment number of wastewater logs; May be used to find average of pH and other fields
                LogCount: acc.LogCount + 1,
              };
            }
          }, {});
        });
        const dailySummary = Object.keys(dailyMap).map(Key => {
          return dailyMap[Key].reduce((acc, cV) => {
            if (Object.keys(acc).length === 0) {
              return {
                ...cV,
                Key,
                Hours: cV.Hours,
                MeterStart: cV.MeterStart,
                MeterEnd: cV.MeterEnd,
                WeightStart: cV.WeightStart,
                WeightEnd: cV.WeightEnd,
                WeightTotal: cV.WeightTotal,
                LogCount: 1,
              };
            }
            else {
              return {
                Key,
                Hours: Math.round(acc.Hours + cV.Hours),
                MeterStart: acc?.MeterStart && acc.MeterStart < cV.MeterStart ? acc.MeterStart : cV.MeterStart,
                MeterEnd: acc?.MeterEnd && acc.MeterEnd > cV.MeterEnd ? acc.MeterEnd : cV.MeterEnd,
                WeightStart: acc?.WeightStart && acc.WeightStart < cV.WeightStart ? acc.WeightStart : cV.WeightStart,
                WeightEnd: acc?.WeightEnd && acc.WeightEnd > cV.WeightEnd ? acc.WeightEnd : cV.WeightEnd,
                WeightTotal: acc.WeightTotal + cV.WeightTotal,
                // Increment number of wastewater logs; May be used to find average of pH and other fields
                LogCount: acc.LogCount + 1,
              };
            }
          }, {});
        });

        dispatch(torchhoodActions.setAll(all));
        dispatch(torchhoodActions.setLastLog(lastLog));
        dispatch(torchhoodActions.setYearlySummary(yearlySummary));
        dispatch(torchhoodActions.setMonthlySummary(monthlySummary));
        dispatch(torchhoodActions.setDailySummary(dailySummary));
        dispatch(torchhoodActions.setPending(false));
      };
      const snapError = error => {
        alert(error.message);
        console.log('useAirPollTorchhood Error: ', error.message, {error});
      };
      unsubscribe = Firestore.onSnapshot(query, snapData, snapError);
    };
    const forgetState = () => {
      dispatch(torchhoodActions.forgetState());
      if (unsubscribe) unsubscribe();
    };

    // Make sure user is authenticated before invoking listener
    Auth.onAuthStateChanged(user => {
      if (user) getState();
      else forgetState();
    });
  }, [dispatch, torchhood.touched]);

  return torchhood;
};

export default useAirPollTorchhood;
