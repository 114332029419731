import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {DataGridPremium, GridActionsCellItem as GridActionsCellItemImport, useGridApiRef as useGridApiRefImport} from '@mui/x-data-grid-premium';

import CustomNoRowsOverlay from './CustomNoRowsOverlay';
import DataGridHeaderImport from './DataGridHeader';
import DataGridExportButtonImport from './DataGridExportButton';

const DataGrid = props => {
  const {pathname} = useLocation();
  const tableName = props?.tableName || 'General';

  // Default styles; Can be overwritten by passing as props;
  const headerBorderColor = props?.headerBorderColor || 'rgb(200, 200, 200)';
  const minBodyHeight = props?.minBodyHeight || '150px';

  const columnVisibilityKey = `columnVisibility:${pathname}:${tableName}`;
  const filterKey = `filter:${pathname}:${tableName}`;
  const paginationKey = `pagination:${pathname}:${tableName}`;
  const sortKey = `sort:${pathname}:${tableName}`;

  return (
    <Box sx={styles({headerBorderColor, minBodyHeight})}>
      <DataGridPremium
        {...props}
        autoHeight={props?.autoHeight || true}
        columns={props?.columns || []}
        density={props?.density || 'compact'}
        disableRowSelectionOnClick={props?.disableRowSelectionOnClick || true}
        initialState={{
          ...(props?.initialState || {}),
          columns: {
            ...(props?.initialState?.columns || {}),
            // Set columnVisibilityModel to last user model saved to sessionStorage; else to initial columnVisibilityModel set at component level; else to default
            columnVisibilityModel: sessionStorage?.[columnVisibilityKey] ? JSON.parse(sessionStorage[columnVisibilityKey]) : props?.initialState?.columns?.columnVisibilityModel || {},
          },
          filter: {
            ...(props?.initialState?.filter || {}),
            // Set filterModel to last user model saved to sessionStorage; else to initial filterModel set at component level; else to default filterModel
            filterModel: sessionStorage?.[filterKey] ? JSON.parse(sessionStorage[filterKey]) : props?.initialState?.filter?.filterModel || {items: []},
          },
          sorting: {
            ...(props?.initialState?.sorting || {}),
            // Set sortModel to last user model saved to sessionStorage; else to initial sortModel set at component level; else to default sortModel
            sortModel: sessionStorage?.[sortKey] ? JSON.parse(sessionStorage[sortKey]) : props?.initialState?.sorting?.sortModel || [],
          },
          pagination: {
            ...(props?.initialState?.pagination || {}),
            // Set page & pageSize to last user model saved to sessionStorage; else to initial both set at component level; else default value
            paginationModel: sessionStorage?.[paginationKey] ? JSON.parse(sessionStorage[paginationKey]) : props?.initialState?.pagination?.paginationModel || {page: 0, pageSize: 10},
          },
        }}
        loading={props?.loading || false}
        onColumnVisibilityModelChange={newModel => sessionStorage.setItem(columnVisibilityKey, JSON.stringify(newModel))}
        onFilterModelChange={newModel => sessionStorage.setItem(filterKey, JSON.stringify(newModel))}
        onPaginationModelChange={newModel => sessionStorage.setItem(paginationKey, JSON.stringify(newModel))}
        onSortModelChange={newModel => sessionStorage.setItem(sortKey, JSON.stringify(newModel))}
        pageSizeOptions={props?.pageSizeOptions || [5, 10, 25]}
        pagination={props?.pagination || true}
        rows={props?.rows || []}
        slots={{noRowsOverlay: CustomNoRowsOverlay, ...props.slots}}
      />
    </Box>
  );
};

const styles = ({headerBorderColor, minBodyHeight}) => ({
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: '#fef1f2',
  },
  '& .MuiDataGrid-virtualScroller': {
    minHeight: `${minBodyHeight} !important`,
  },
  '& .MuiDataGrid-row--lastVisible': {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: `2px solid ${headerBorderColor} !important`,
  },
  '& .MuiDataGrid-columnSeparator': {
    color: `${headerBorderColor} !important`,
  },
  '& .MuiDataGrid-overlayWrapperInner': {
    minHeight: `${minBodyHeight} !important`,
  },
});
DataGrid.propTypes = {
  autoHeight: PropTypes.bool,
  columns: PropTypes.array,
  density: PropTypes.string,
  disableRowSelectionOnClick: PropTypes.bool,
  headerBorderColor: PropTypes.string,
  initialState: PropTypes.object,
  loading: PropTypes.bool,
  minBodyHeight: PropTypes.string,
  pageSizeOptions: PropTypes.array,
  pagination: PropTypes.bool,
  rows: PropTypes.array,
  slots: PropTypes.object,
  tableName: PropTypes.string,
};
export default DataGrid;

export const DataGridHeader = DataGridHeaderImport;
export const ExportButton = DataGridExportButtonImport;
export const GridActionsCellItem = GridActionsCellItemImport;
export const useGridApiRef = useGridApiRefImport;
