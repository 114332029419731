/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const AdminDownload = (props) => (
  <SvgIcon viewBox='0 0 64 64' {...props}>
    <g>
      <path d="m30.5858 33.4142-5.5-5.5c-.7811-.781-.7811-2.0474 0-2.8284.781-.7811 2.0474-.7811 2.8284 0l2.0858 2.0858v-10.6716c0-1.1046.8954-2 2-2s2 .8954 2 2v10.6716l2.0858-2.0858c.781-.7811 2.0474-.7811 2.8284 0 .7811.781.7811 2.0474 0 2.8284l-5.5 5.5c-.781.7811-2.0474.7811-2.8284 0z" />
      <path d="m45 38c0 1.1046-.8954 2-2 2h-22c-1.1046 0-2-.8954-2-2s.8954-2 2-2h22c1.1046 0 2 .8954 2 2z" />
      <path clipRule="evenodd" d="m34.0099 2.45635c-1.3181-.34463-2.7017-.34463-4.0198 0l-18 4.70642c-3.53877.92527-5.9901 4.14123-5.9901 7.80033v16.2669c0 11.6704 6.2405 22.4414 16.3359 28.1606l.8499.4814c5.474 3.1012 12.1544 3.1012 17.6284 0l.8499-.4814c10.0954-5.7192 16.3359-16.4902 16.3359-28.1606v-16.2669c0-3.6591-2.4513-6.87506-5.9901-7.80033zm-3.0079 3.86991c.6546-.17118 1.3414-.17118 1.996 0l18 4.70644c1.7556.459 3.002 2.067 3.002 3.9304v16.2669c0 10.243-5.4772 19.6778-14.3076 24.6803l-.8498.4814c-4.2509 2.4082-9.4343 2.4082-13.6852 0l-.8498-.4814c-8.8304-5.0025-14.3076-14.4373-14.3076-24.6803v-16.2669c0-1.8634 1.2464-3.4714 3.002-3.9304z" fillRule="evenodd" />
    </g>
  </SvgIcon>
);

export default AdminDownload;
