import {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Typography} from '@mui/material';
import {StyledPaper} from 'components';
import {Link, useLocation} from 'react-router-dom';

const HeaderMenu = ({buttonItems = [], icon, title}) => {
  const HeaderIcon = icon;
  const location = useLocation();
  const buttonProps = useCallback((path, index) => {
    const isExactUrl = Boolean(location.pathname === path);
    const isSamePath = Boolean(location.pathname.startsWith(path));
    return {
      component: isExactUrl ? undefined : Link,
      disableRipple: true,
      sx: {
        'backgroundColor': isSamePath ? 'rgba(0, 0, 0, 0.04)' : 'rgba(0, 0, 0, 0)',
        'borderColor': isSamePath ? '#ED1C24' : '#6D6E71',
        'color': isSamePath ? '#ED1C24' : '#6D6E71',
        'ml': index === 0 ? '0px' : '4px',
      },
      to: isExactUrl ? undefined : path,
      variant: 'outlined',
    };
  }, [location]);

  return (
    <StyledPaper>
      <Box sx={styles.headerLayout(buttonItems)}>
        <HeaderIcon sx={styles.headerIcon} />
        <Typography variant='h4' color='primary'>{title}</Typography>
      </Box>
      <Box sx={styles.buttonOverflow}>
        {buttonItems.map((item, index) => (
          <Button key={item.path} {...buttonProps(item.path, index)}>
            {item.title}
          </Button>
        ))}
      </Box>
    </StyledPaper>
  );
};

const styles = {
  buttonOverflow: {overflowX: 'auto', whiteSpace: 'nowrap'},
  headerIcon: {color: 'secondary.main', fontSize: '2.5rem', marginRight: '16px'},
  headerLayout: buttonItems => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: buttonItems?.length > 0 ? '16px' : '0px',
  }),
};
HeaderMenu.propTypes = {
  buttonItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  })),
  icon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.node,
    PropTypes.elementType,
  ]).isRequired,
  title: PropTypes.string.isRequired,
};
export default HeaderMenu;
