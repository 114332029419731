import {Typography} from '@mui/material';
import {StyledPaper} from 'components';
import {ButtonPress} from 'icons';

const Placeholder = () => {
  return (
    <StyledPaper>
      <div style={{position: 'relative', textAlign: 'center'}}>
        <Typography variant='h4' sx={styles.placeholderText}>
          Choose Option Above to Continue
        </Typography>
        <ButtonPress sx={styles.placeholderIcon} />
      </div>
    </StyledPaper>
  );
};

const styles = {
  placeholderIcon: {
    fontSize: {
      xs: '10rem',
      sm: '15rem',
    },
    color: 'primary.light',
    opacity: 0.3,
  },
  placeholderText: {
    fontSize: {xs: '1.5rem'},
    alignItems: 'center',
    color: 'secondary.dark',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },
};
export default Placeholder;
