/* eslint-disable max-len */
import {SvgIcon} from '@mui/material';

const Inspections = (props) => (
  <SvgIcon viewBox='0 0 512 512' {...props}>
    <g>
      <path d="m90 39.789-81.211 81.211h81.211z" />
      <path d="m180 256c0-74.443 60.557-135 135-135 5.136 0 10.01.954 15 1.516v-91.516h-210v120h-120v330h330v-91.516c-4.99.562-9.864 1.516-15 1.516-74.443 0-135-60.557-135-135zm-105 201.211-40.605-40.605 21.211-21.211 19.394 19.394 49.395-49.395 21.211 21.211zm0-90-40.605-40.605 21.211-21.211 19.394 19.394 49.395-49.395 21.211 21.211zm0-90-40.605-40.605 21.211-21.211 19.394 19.394 49.395-49.395 21.211 21.211z" />
      <path d="m512 432-113.165-113.376c13.118-17.525 21.165-39.051 21.165-62.624 0-57.99-47.01-105-105-105s-105 47.01-105 105 47.01 105 105 105c23.573 0 45.099-8.047 62.624-21.165l113.165 113.376z" />
    </g>
  </SvgIcon>
);

export default Inspections;
