import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  Manufacturer: true,
  HazIdentification: true,
  Ingredients: true,
  Documents: true,
  Images: true,
  Reviews: true,
};

const sdsViewSlice = createSlice({
  name: 'sdsView',
  initialState,
  reducers: {
    setSdsView: (state, action) => {
      if (typeof action?.payload?.Manufacturer === 'boolean') state.Manufacturer = action.payload.Manufacturer;
      if (typeof action?.payload?.HazIdentification === 'boolean') state.HazIdentification = action.payload.HazIdentification;
      if (typeof action?.payload?.Ingredients === 'boolean') state.Ingredients = action.payload.Ingredients;
      if (typeof action?.payload?.Documents === 'boolean') state.Documents = action.payload.Documents;
      if (typeof action?.payload?.Images === 'boolean') state.Images = action.payload.Images;
      if (typeof action?.payload?.Reviews === 'boolean') state.Reviews = action.payload.Reviews;
    },
  },
});

export const sdsViewActions = sdsViewSlice.actions;

export default sdsViewSlice.reducer;
