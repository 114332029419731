import PropTypes from 'prop-types';
import DetailsFieldList from './DetailsFieldList';
import DetailsTitle from './DetailsTitle';
import DetailsButtons from './DetailsButtons';
import {Paper} from '@mui/material';

const DetailsPanel = props => {
  // this splits up the style props passed to iconProps from the other props. this allows for correct spreading of props to icon component
  const {style: iconStyle, ...otherIconProps} = props?.iconProps || {};
  const titleProps = {
    title: props.title,
    titleVariant: props?.titleVariant || 'h6',
    titleStyle: props.titleStyle,
    subtitle: props?.subtitle || null,
    subtitleStyle: props.subtitleStyle,
    subtitleLink: props?.subtitleLink || null,
    subtitleVariant: props?.subtitleVariant || 'body2',
  };
  const IconComponent = props.icon ? props.icon : null;

  return (
    <Paper sx={styles.paper} {...props.paperProps}>
      {IconComponent !== null && (
        <IconComponent sx={{...styles.icon, ...iconStyle}} {...otherIconProps} />
      )}
      <DetailsTitle {...titleProps} icon={props.icon} />
      <DetailsFieldList fields={props?.fields || []} />
      <DetailsButtons buttons={props?.buttons || []} />
    </Paper>
  );
};

const styles = {
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '16px',
    textAlign: 'center',
  },
  icon: {
    fill: '#ed1c24',
    fontSize: '10em',
    marginBottom: '16px',
    width: '100%',
  },
};
DetailsPanel.propTypes = {
  /** Any props you want to be passeed to the surrounding Paper component */
  paperProps: PropTypes.object,
  /** An array of objects mapped over for each of the sections in the list. any props not specified here that are passed will be attached to the MaterialUI Button component  */
  buttons: PropTypes.arrayOf(PropTypes.shape({
    /** The label for the button */
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    /** The function ran on click of the button */
    onClick: PropTypes.func.isRequired,
    /** A boolean to disabled the button. defaults to false */
    disabled: PropTypes.bool,
    /** A boolean to hide or show the button */
    visable: PropTypes.bool,
  })),
  fields: PropTypes.arrayOf(PropTypes.shape({
    /** The label for the field */
    label: PropTypes.string.isRequired,
    /** The value for the field */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    /** A boolean to hide or show the field */
    visable: PropTypes.bool,
    /** any field specific props to pass to the TextField component */
    textFieldProps: PropTypes.object,
  })).isRequired,
  /** The title of the section */
  title: PropTypes.string.isRequired,
  /** Any one of the typography variants of MaterialUI to be applied to the title */
  titleVariant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2']),
  /** Any styling that will be applied to the title Typography */
  titleStyle: PropTypes.object,
  /** The sting displayed as a subtitle */
  subtitle: PropTypes.string,
  /** Any styling that will be applied to the subtitle Typography */
  subtitleStyle: PropTypes.object,
  /** Any one of the typography variants of MaterialUI to be applied to the subtitle */
  subtitleVariant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2']),
  /** If the subtitle is a link, this holds the url */
  subtitleLink: PropTypes.string,
  /** The icon to be used above the title */
  icon: PropTypes.elementType,
  /** Props that you want passed to the icon */
  iconProps: PropTypes.object,
};
export default DetailsPanel;
