import {useEffect, useRef} from 'react';
import {batch, useDispatch, useSelector} from 'react-redux';
import {DateTime} from 'luxon';
import {Auth, Firestore} from 'firebaseConfig';
import {wastewaterActions} from 'state/wastewater/slice';

const useWastewaterLog = () => {
  const wastewater = useSelector(state => state.wastewater);
  const dispatch = useDispatch();
  const duplicateRender = useRef(false);

  useEffect(() => {
    // Prevent duplicate renders caused by React 18 strict mode
    if (duplicateRender.current) return;
    duplicateRender.current = true;

    // Only run remainder of code if hook untouched; Prevents duplicate invocations;
    if (wastewater.touched) return;
    dispatch(wastewaterActions.setTouched(true));

    let unsubscribe = null;
    // Create functions to get state or forget state depending on user's auth status
    const getState = () => {
      const minLogYear = DateTime.now().get('year') - 5;
      const query = Firestore.query(
        Firestore.collection('Wastewater'),
        Firestore.where('Date', '>=', new Date(`${minLogYear}-01-01`))
      );
      const snapData = snap => {
        const all = [];
        let lastLog = {};
        const yearlyMap = {};
        const monthlyMap = {};

        snap.forEach(doc => {
          const docData = doc.data();
          const DateMillis = docData.Date.toMillis(); // Convert Firetore timestamp to Redux friendly value
          const DateYear = DateTime.fromMillis(DateMillis).toFormat('yyyy');
          const DateMonth = DateTime.fromMillis(DateMillis).toFormat('yyyy/LL');
          // console.log({DateYear, DateMonth});
          const formattedDoc = {
            ...docData,
            Date: DateMillis,
            DateYear,
            DateMonth,
            DocId: doc.id,
            System: {...docData.System, EnteredOn: docData.System.EnteredOn.toMillis()},
          };

          // Add all Wastewater log documents to all array
          all.push(formattedDoc);
          // Ensure last Wasterwater log entered is used for lastLog value
          if (!lastLog?.Date || lastLog.Date < DateMillis) lastLog = formattedDoc;
          // Map Wastewater documents using DateYear as the key; Will be used to aggregate fields by year
          yearlyMap[DateYear] = yearlyMap?.[DateYear] ? [...yearlyMap[DateYear], formattedDoc] : [formattedDoc];
          // Map Wastewater documents using DateMonth as the key; Will be used to aggregate fields by year and month
          monthlyMap[DateMonth] = monthlyMap?.[DateMonth] ? [...monthlyMap[DateMonth], formattedDoc] : [formattedDoc];
        });

        // Aggregate all values in yearlyMap & monthlyMap by their respective keys
        const yearlySummary = Object.keys(yearlyMap).map(Key => {
          return yearlyMap[Key].reduce((acc, cV) => {
            if (Object.keys(acc).length === 0) return {...cV, Key, LogCount: 1};
            else {
              return {
                Key: acc.Key,
                Acid: acc.Acid + cV.Acid,
                Discharge: acc.Discharge + cV.Discharge,
                Filtercake: acc.Filtercake + cV.Filtercake,
                KOH: acc.KOH + cV.KOH,
                MAG: acc.MAG + cV.MAG,
                WasteH2O: acc.WasteH2O + cV.WasteH2O,
                // Increment number of wastewater logs; May be used to find average of pH and other fields
                LogCount: acc.LogCount + 1,
                pH: acc.pH + cV.pH,
              };
            }
          }, {});
        });
        const monthlySummary = Object.keys(monthlyMap).map(Key => {
          return monthlyMap[Key].reduce((acc, cV) => {
            if (Object.keys(acc).length === 0) return {...cV, Key, LogCount: 1};
            else {
              return {
                Key: acc.Key,
                Acid: acc.Acid + cV.Acid,
                Discharge: acc.Discharge + cV.Discharge,
                Filtercake: acc.Filtercake + cV.Filtercake,
                KOH: acc.KOH + cV.KOH,
                MAG: acc.MAG + cV.MAG,
                WasteH2O: acc.WasteH2O + cV.WasteH2O,
                // Increment number of wastewater logs; May be used to find average of pH and other fields
                LogCount: acc.LogCount + 1,
                pH: acc.pH + cV.pH,
              };
            }
          }, {});
        });

        // Batch redux dispatch to push setAll and SetPending simultaneously
        batch(() => {
          dispatch(wastewaterActions.setAll(all));
          dispatch(wastewaterActions.setLastLog(lastLog));
          dispatch(wastewaterActions.setYearlySummary(yearlySummary));
          dispatch(wastewaterActions.setMonthlySummary(monthlySummary));
          dispatch(wastewaterActions.setPending(false));
        });
      };
      const snapError = error => {
        alert(error.message);
        console.log('useWastewater Error: ', error.message, {error});
      };
      unsubscribe = Firestore.onSnapshot(query, snapData, snapError);
    };
    const forgetState = () => {
      dispatch(wastewaterActions.forgetState());
      if (unsubscribe) unsubscribe();
    };

    // Make sure user is authenticated before invoking listener
    Auth.onAuthStateChanged(user => {
      if (user) getState();
      else forgetState();
    });
  }, [dispatch, wastewater.touched]);

  return wastewater;
};

export default useWastewaterLog;
